import Vue from 'vue'
import Component from './ConfirmModal'

import i18n from '@/plugins/i18n'

function install(Vue) {
  Vue.prototype.$$confirm = ({
    title,
    text,
    confirmText,
    confirmColor,
    cancelText,
    onConfirm,
    onCancel,
  }) => {
    return new Promise(resolve => {
      const Alert = Vue.extend(Component)
      const el = document.createElement('div')
      document.getElementById('confirm-modal').appendChild(el)
      new Alert({
        el,
        i18n,
        propsData: {
          title,
          text,
          confirmText,
          confirmColor,
          cancelText,
          callback: resolve,
          onConfirm,
          onCancel,
        },
      })
    })
  }
}

const Confirm = { install }

Vue.use(Confirm)
