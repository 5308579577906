<template>
  <div>
    <template v-for="(button, index) in buttons">
      <button
        v-if="!button.hidden"
        :key="index"
        v-text="button.label"
        :class="['btn', button.class]"
        @click.stop="button.handler"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'button-group',

  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
}
</script>
