const state = {
  counter: 0,
  isMobile: false,
}

const getters = {
  isLoading(state) {
    return state.counter > 0
  },
}

const mutations = {
  TOGGLE_MOBILE(state) {
    state.isMobile = !state.isMobile
  },
  INCREASE_COUNTER(state) {
    state.counter = state.counter + 1
  },
  DECREASE_COUNTER(state) {
    state.counter = Math.max(state.counter - 1, 0)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
