<template>
  <div>
    <div class="font-bold text-xl flex">Profile Settings</div>

    <hr class="my-2" />

    <div class="flex flex-col">
      <label class="label font-bold">
        <span class="label-text">Username</span>
      </label>
      <d-input class="w-full" v-model="_username" />
      <div class="flex justify-end">
        <d-btn class="text-xs" @click="updateUsername">Save</d-btn>
      </div>
    </div>

    <div class="flex flex-col">
      <label class="label font-bold">
        <span class="label-text">Update Password</span>
      </label>

      <label class="label text-xs font-semibold text-gray-400">
        <span class="">Old Password</span>
      </label>
      <d-input class="w-full" v-model="oldPassword" :type="passwordInputType" />

      <label class="label font-semibold text-xs text-gray-400">
        <span>New Password</span>
      </label>
      <d-input
        class="w-full"
        v-model="newPassword1"
        :type="passwordInputType"
      />

      <label class="label font-semibold text-xs text-gray-400">
        <span>Retype New Password</span>
      </label>
      <div v-if="error" class="text-error my-2 font-bold text-right w-full">
        {{ $t(this.error) }}
      </div>
      <d-input
        class="w-full"
        v-model="newPassword2"
        :type="passwordInputType"
      />

      <div class="w-full flex justify-end cursor-pointer">
        <span
          class="underline text-xs font-semibold text-gray-600"
          @click="showPassword = !showPassword"
        >
          {{ showPasswordText }}
        </span>
      </div>
      <div class="flex justify-end mt-2">
        <d-btn class="text-xs" @click="updatePassword">Update password</d-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateProfileModal',

  data: () => ({
    username: '',
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
    showPassword: false,
    error: '',
  }),

  computed: {
    _username: {
      get() {
        return this.username ? this.username : this.$store.state.auth.user.name
      },
      set(val) {
        this.username = val
      },
    },
    passwordInputType() {
      return this.showPassword ? 'text' : 'password'
    },
    showPasswordText() {
      return this.showPassword ? 'Hide Password' : 'Show Password'
    },
  },

  methods: {
    async updateUsername() {
      const payload = {
        name: this._username,
      }
      const { status } = await this.$http.post('/api/v2/username', payload)
      if (status) {
        this.$toast.success('Username update successfully')
        return
      }
      this.$toast.error('This username already taken')
    },
    async updatePassword() {
      if (this.newPassword1.length < 8) {
        this.$toast.error('Please provide strong password')
        return
      }
      if (this.newPassword1 !== this.newPassword2) {
        this.$toast.error('Password mismatch!!!')
        return
      }
      const payload = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword1,
      }
      const { status } = await this.$http.post('/api/v2/password', payload)
      if (status) {
        this.$toast.success('Password update successfully')
        return
      }
      this.$toast.error('Password is not correct')
    },
  },
}
</script>
