const MONTH_STRS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function buildQuery(obj) {
  return Object.keys(obj)
    .map((key) => {
      const value = JSON.stringify(obj[key])
      return `${key}=${value}`
    })
    .join('&')
}

export function localeStringToNumber(str) {
  str = str.replaceAll(',', '')
  return Number(str)
}

export function formatNumber(number, __default = '--') {
  const res = Number(number)
  return isNaN(res) || res === 0 ? __default : res.toLocaleString()
}

export function formatDate(value, short = false) {
  if (!value) {
    return '-'
  }
  const date = new Date(value)
  const year = date.getFullYear()
  const month = twoDigit(date.getMonth() + 1)
  const day = twoDigit(date.getDate())
  const hour = twoDigit(date.getHours())
  const min = twoDigit(date.getMinutes())
  const sec = twoDigit(date.getSeconds())
  if (short) {
    return `${month}/${day} ${hour}:${min}`
  }
  return `${year}/${month}/${day} ${hour}:${min}:${sec}`
}

export function dateStrings(value) {
  const date = new Date(value)
  const year = date.getFullYear()
  const month = twoDigit(date.getMonth() + 1)
  const day = twoDigit(date.getDate())
  const hour = twoDigit(date.getHours())
  const min = twoDigit(date.getMinutes())
  const sec = twoDigit(date.getSeconds())
  return {
    year,
    month,
    day,
    hour,
    min,
    sec,
  }
}

export function date(value) {
  const date = new Date(value)
  const month = MONTH_STRS[date.getMonth()]
  const day = twoDigit(date.getDate())
  const hour = twoDigit(date.getHours())
  const min = twoDigit(date.getMinutes())
  return `${month}. ${day} ${hour}:${min}`
}

export function twoDigit(value) {
  if (Math.abs(value) < 10) {
    return `0${value}`
  }
  return value.toString()
}

export function fix2Number(number) {
  return _fixNumber(number, 2)
}

export function fix4Number(number) {
  return _fixNumber(number, 4)
}

function _fixNumber(num, n) {
  const j = 10 ** n
  return Math.round(num * j) / j
}

export const formatUSD = (someNumber) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(someNumber)
}
