import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store/index.js'

Vue.use(VueAxios, axios)

Vue.axios.interceptors.request.use((config) => {
  config.baseURL = process.env.VUE_APP_API_URL || `https://sapi.${global.DOMAIN}`
  if (!config.skipLoading) {
    store.commit('common/INCREASE_COUNTER')
  }
  config.headers.Authorization = `Bearer ${store.state.auth.token}`
  return config
})

Vue.axios.interceptors.response.use(
  (response) => {
    if (!response.config.skipLoading) {
      store.commit('common/DECREASE_COUNTER')
    }
    return response.data.result || response.data
  },
  (error) => {
    store.commit('common/DECREASE_COUNTER')
    return Promise.reject(error)
  }
)
