export default {
  'Edit Account Group': 'Edit Account Group',
  'New Account Group': 'New Account Group',
  'Account Group Name': 'Account Group Name',
  'Summary': 'Summary',
  'Multiple': 'Multiple',
  'RANDOM (0.9~1.1)': 'RANDOM (0.9~1.1)',
  'Exit Day Range (Lower)': 'Exit Day Range (Lower)',
  'Exit Day Range (Upper)': 'Exit Day Range (Upper)',
  'Auto Entry': '自動エントリ設定',
  'Chart': 'Chart',
  'Performance': '成績',
  'Filter': '選択',
  'Select accounts': 'アカウント選択',
  'Select logics': 'ロジック選択',
  'Inital Amount': 'デポジット',
  'Mark Price': 'Mark Price',
  'Open Only': 'Open Only',
  'Content': 'Content',
  'Mark': 'Mark',
  'Entry / Filled': 'Entry / Filled',
  'IVBand': 'IVBand',
  'Reset': 'Reset',
  'Setting Price': 'Setting Price',
  'Twitter Setting': 'Twitter Setting',
  'Twitter': 'Twitter',
  'Clear': '取消',
  'Account Name': 'アカウント名',
  'Account': 'アカウント',
  'Accounts': 'アカウント一覧',
  'accounts': '口座',
  'Action': '設定',
  'Actions': 'Actions',
  'Add': '追加',
  'All Orders': 'All Orders',
  'all orders': 'All Orders',
  'Amount (USD)': 'Amount (USD)',
  'API key': 'APIキー',
  'Apply': 'Apply',
  'Balance': 'Balance',
  'balance': 'Balance',
  'Batch edit': '全同一',
  'Batch Entry': '一括発注',
  'Batch update': '一括登録',
  'Binance Stats': 'Binance Stats',
  'Bot Name': 'ロジック名',
  'Bots': 'Bots',
  'BTC Price': 'BTC Price',
  'Buy Sell': 'Buy Sell',
  'Cancel': 'キャンセル',
  'ChartError': 'Errors',
  'ChartPL': '成績',
  'Check span': 'Check span',
  'Check': '選択',
  'Clone': '複製',
  'Close Position': 'クローズ',
  'Close': 'クローズ',
  'Revert': '即時決済',
  'closeSuccess': '発注を決済しました。',
  'Collections': 'コレクション',
  'Config': 'Config',
  'Copy Logic': 'ロジックを複製',
  'Create': '登録',
  'Dashboard': 'ダッシュボード',
  'Date': 'Date',
  'Datetime': 'Datetime',
  'Delay (seconds)': 'ズレ（秒）',
  'Delete Account': 'アカウント削除',
  'Delete Group': 'グループ削除',
  'Delete': '削除',
  'Detail': '詳細',
  'Edit Account': 'アカウント編集',
  'Edit Group': 'グループ編集',
  'Edit': '編集',
  'Email': 'Email',
  'Entry price': 'エントリー価格',
  'Equity': 'Equity',
  'Exchange accounts': '取引先口座',
  'Exchange': '取引所',
  'Filled': 'Filled',
  'Funding Rate': 'Funding Rate',
  'GMX Board': 'GMX Board',
  'Group Name': 'グループ名',
  'Group': 'グループ',
  'groups': 'グループ',
  'Hide': '非表示',
  'ID': 'ID',
  'Last Update': 'Last Update',
  'Limit Order': 'Limit Order',
  'Limit': 'LIMIT',
  'Link': 'Link',
  'Liquidation': 'Liquidation',
  'Logic Detail': 'ロジックの詳細',
  'Logic Name': 'ボット名',
  'Logic': 'Logic',
  'Logics': 'Logics',
  'Losscut': 'Losscut',
  'lxvx': 'Lxvx',
  'Make order': '発注',
  'Manual Orders': 'マニュアル発注',
  'Market Points': 'マーケットポイント',
  'Market': 'MARKET',
  'Memo': 'メモ',
  'Mint history of stable coins': 'ミント履歴',
  'Move DOWN': '下へ移行',
  'Move UP': '上へ移行',
  'myBots': 'マイボット',
  'myLogics': 'My Logics',
  'Name is required': 'アカウント名は必須です。',
  'New Account': 'アカウント登録',
  'New Group': 'グループ登録',
  'New Order': '発注',
  'news': 'ニュース',
  'No Accounts': 'アカウントが登録されていません。',
  'No available data': 'データはありません。',
  'No': 'NO',
  'Notification Setting': 'Notification Setting',
  'OmTrader Orders': 'OmTrader Orders',
  'Only Manual order': 'Only Manual order',
  'Order At': 'Order At',
  'Order ID': 'Order ID',
  'Order Type': '発注タイプ',
  'Order': '発注',
  'orders': '発注履歴',
  'Passphrase': 'パスフレーズ',
  'Please enable access from IP: 52.192.42.250': 'IP『52.192.42.250』からのアクセスを有効してください。',
  'Please select account': 'アカウントを選択してください。',
  'Please select exchange': '取引先を選択してください。',
  'Please select group': 'グループを選択してください。',
  'Position': 'ポジション',
  'Positions': 'ポジション',
  'presets': 'Presets',
  'Price is required': 'PRICEは必須です。',
  'Price': 'PRICE',
  'Product ID': '商品ID',
  'ProductID is required': '商品IDは必須です。',
  'ProductID': 'ProductID',
  'Profit Loss': 'Profit Loss',
  'Profit': 'Profit',
  'Qty': 'Qty',
  'Reduce Only': 'Reduce Only',
  'Remove Memo': 'Remove Memo',
  'resetFailed': 'リセットできませんでした。再度お試してをしてください。',
  'resetLogic': 'ロジックをリセットする',
  'resetLogicConfirmText': 'YES',
  'resetLogicText': 'ロジックをリセットします、よろしいでしょうか。',
  'resetSuccess': 'リセットしました。',
  'Save Template': 'Save Template',
  'Save': '保存',
  'Secret key': 'APIシークレット',
  'Show': '表示',
  'Side': 'Side',
  'Site': 'Site',
  'Sl': 'Sl',
  'Source': 'Source',
  'Span': '間隔',
  'Stable Coin': 'Stable Coin',
  'stats': 'Market Statistics',
  'Status': 'ステータス',
  'Stop Order': 'Stop Order',
  'Stop Price': 'Stop Price',
  'Stop': 'STOP',
  'Sub Account': 'サブアカウント',
  'Symbol': '通貨ペア',
  'Token': 'トークン',
  'Total': '個数',
  'Tp': 'Tp',
  'turnOffConfirmText': 'YES',
  'turnOffLogic': 'ロジックをOFFにする',
  'turnOffText': 'ロジックをOFFにします、よろしいでしょうか。',
  'turnOnConfirmText': 'YES',
  'turnOnLogic': 'ロジックをONにする',
  'turnOnText': 'ロジックをONにします、よろしいでしょうか。',
  'Type': 'Type',
  'UID': 'UID',
  'Unrealised PNL': 'Unrealised PNL',
  'Update': '更新',
  'Updated': '更新しました!!!',
  'updateFailed': '更新できませんでした。再度お試してをしてください。',
  'updateSuccess': '完了',
  'Users Management': 'Users Management',
  'Volume (USD)': 'Volume (USD)',
  'Volume is required': 'VOLUMEは必須です。',
  'Volume': 'VOLUME',
  'xChart': 'X Chart',
  'Yes': 'Yes',
  'actions': {
    reset: 'リセット',
    on: 'ON',
    off: 'OFF',
    batchEdit: '一括編集',
  },
  'logicModal': {
    success: 'ロジックを更新しました。',
    fail: 'ロジックを更新できませんでした。再度お試してをしてください。',
  },
}
