<template>
  <div class="tabs">
    <a
      v-for="(tab, idx) of tabs"
      class="tab tab-lifted"
      :key="idx"
      :class="isActive(tab) ? 'tab-active font-bold' : ''"
      @click="select(tab)"
    >
      {{ tab.title }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'd-tabs',

  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    active: null,
  }),

  created() {
    this.active = this.tabs[0]
  },

  computed: {
    map() {
      return this.tabs.reduce((acc, curr) => {
        acc[curr.key] = curr
        return acc
      }, {})
    },
  },

  methods: {
    select(item) {
      this.active = item
    },
    isActive(item) {
      return this.active.key === item.key
    },
  },

  watch: {
    active(tab) {
      this.$emit('input', tab)
    },
  },
}
</script>
