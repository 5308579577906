module.exports = {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    $$daisyDisbaleable() {
      return this.disabled ? 'disabled' : ''
    },
  },
}
