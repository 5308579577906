import Vue from 'vue'

const _default = {
  weekGap: {},
  prices: {},
  oi: {},
  misc: {},
  stats: {},
  tool1: {},
  longshort: [],
  tool2: {},
}

function _load() {
  try {
    return {
      _default,
      ...JSON.parse(localStorage.market),
    }
  } catch (err) {
    // ignore
  }
  return _default
}

export default {
  namespaced: true,
  state: _load(),
  mutations: {
    UPDATE_LONGSHORT(state, data) {
      const binance = data.binance.ratio
      const bitmex = data.bitmex.ratio
      const bitfinex = data.bitfinex.ratio
      state.longshort = [
        {
          label: 'Binance Top Trader (Accounts)',
          day: binance.topLongShortAccountRatio.rate,
          account: binance.topLongShortAccountRatio.curent,
          link: 'https://www.binance.com/en/futures/funding-history/4',
        },
        {
          label: 'Binance Top Trader (Position)',
          day: binance.topLongShortPositionRatio.rate,
          account: binance.topLongShortPositionRatio.curent,
        },
        {
          label: 'BINANCE: Global Traders',
          day: binance.globalLongShortAccountRatio.rate,
          account: binance.globalLongShortAccountRatio.curent,
        },
        {
          label: 'BITMEX: Global Traders',
          day: bitmex.globalLongShortAccountRatio.rate,
          account: bitmex.globalLongShortAccountRatio.curent,
        },
        {
          label: 'BITFINEX: Global Traders',
          day: bitfinex.globalLongShortAccountRatio.rate,
          account: bitfinex.globalLongShortAccountRatio.curent,
        },
      ]
    },
    UPDATE_WEEKGAP(state, data) {
      state.weekGap = data
    },
    UPDATE_PRICES(state, { price, symbol }) {
      state.misc.marketPrices[symbol] = price
    },
    UPDATE_MISC(state, misc = {}) {
      state.misc = misc
      misc?.weekGap && (state.weekGap = misc.weekGap)
    },
    UPDATE_STATS(state, stats) {
      state.stats = stats
    },
    UPDATE_MISC_FROM_TOOL(state, tool2) {
      state.tool2 = tool2.result || tool2
    },
    UPDATE_OI(state, data) {
      state.oi = data
    },
  },
  actions: {
    syncStats({ commit }) {
      const url = 'https://tool.cryp-trades.com/api/market/stats'
      Vue.$http.get(url, { skipLoading: true }).then((res) => {
        commit('UPDATE_STATS', res.result || res)
      })
    },
    sync({ commit }) {
      Vue.$http
        .get('/api/v2/markets/information', { skipLoading: true })
        .then((misc) => commit('UPDATE_MISC', misc || {}))
      Vue.$http
        .get('/api/v2/markets/oi', { skipLoading: true })
        .then((data) => commit('UPDATE_OI', data))
      Vue.$http
        .get('https://tool.cryp-trades.com/api/v1/markets/misc/ls-checker', {
          skipLoading: true,
        })
        .then((data) => commit('UPDATE_LONGSHORT', data))
    },
  },
  getters: {
    misc(state) {
      return state.misc || {}
    },
    marketPrices(state) {
      return state.misc?.marketPrices || {}
    },
    btcusd(state) {
      return state.misc.marketPrices?.BTCUSD || 0
    },
    ethusd(state) {
      return state.misc.marketPrices?.ETHUSD || 0
    },
    // -------------------------------------------------------------------------
    statictics(state) {
      const { usdtusd } = state.tool2
      if (!usdtusd) {
        return []
      }

      const usdrmb = {
        now: state.tool2.investing.USDRMB,
        last: state.tool2.investing.PREV_USDRMB,
      }

      const sp500 = {
        now: state.tool2.investing.SP500,
        last: state.tool2.investing.PREV_SP500,
      }

      const vix = {
        now: state.tool2.investing.VIX,
        last: state.tool2.investing.PREV_VIX,
      }

      // const bold = {
      //   now: state.tool2.investing.BOND_10Y,
      //   last: state.tool2.investing.PREV_BOND_10Y,
      // }

      const gold = {
        now: state.tool2.investing.GOLD,
        last: state.tool2.investing.PREV_GOLD,
      }

      const mempool = state.tool2.blockchain.mempool
      const hashrate = state.tool2.blockchain.hashrate

      return [
        {
          label: 'USDTUSD',
          value: usdtusd.now,
          rate: usdtusd.now / usdtusd.last,
          mid: 0.998,
          diff: 0.003,
        },
        {
          label: 'USDRMB',
          value: usdrmb.now,
          rate: usdrmb.now / usdrmb.last,
          mid: 1,
          diff: 0.03,
        },
        {
          label: 'SP500',
          value: sp500.now,
          rate: sp500.now / sp500.last,
          mid: 1,
          diff: 0.03,
        },
        {
          label: 'VIX',
          value: vix.now,
          rate: vix.now / vix.last,
          mid: 1,
          diff: 0.05,
          reverse: true,
        },
        // {
        //   label: 'BOLD',
        //   value: bold.now,
        //   rate: bold.now / bold.last,
        //   mid: 1,
        //   diff: 0.05,
        // },
        {
          label: 'GOLD',
          value: gold.now,
          rate: gold.now / gold.last,
          mid: 1,
          diff: 0.05,
        },
        {
          label: 'Hash Rate',
          value: hashrate.now,
          rate: hashrate.now / hashrate.last,
          mid: 1,
          diff: 0.05,
        },
        {
          label: 'Mempool',
          value: mempool.now,
          rate: mempool.now / mempool.last,
          mid: 1,
          diff: 0.05,
        },
      ]
    },
    stats(state) {
      const stats = state.stats
      const items = Object.keys(stats).map((ex) => {
        let exItems = []
        exItems = exItems.concat(
          Object.keys(stats[ex]).map((symbol) => {
            return {
              exchange: ex,
              symbol: [symbol],
              ...stats[ex][symbol],
            }
          })
        )
        return exItems
      })
      return items
        .reduce((acc, curr) => {
          return [...curr.map((_item) => _item), ...acc]
        }, [])
        .sort((a, b) => a.order - b.order)
    },
  },
}
