<template>
  <div>
    <div class="text-gray-700 font-medium">Language</div>
    <ul class="compact menu py-4">
      <li v-for="(item, idx) of locales" :key="idx" class="mt-1">
        <a
          @click="setLocale(item.locale)"
          :class="item.activeClass"
          class="rounded-md"
        >
          <div :v-html="item.svg" />
          <div class="font-bold">{{ item.title }}</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import JapanFlag from '@/assets/svg/japanFlag.svg'
import EngFlag from '@/assets/svg/engFlag.svg'

export default {
  name: 'language-menu',

  computed: {
    locales() {
      return [
        {
          svg: JapanFlag,
          locale: 'ja',
          activeClass: this.$root.$i18n.locale === 'ja' ? 'active' : '',
          title: '日本語',
        },
        {
          svg: EngFlag,
          locale: 'en',
          activeClass: this.$root.$i18n.locale === 'en' ? 'active' : '',
          title: 'English',
        },
      ]
    },
  },

  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale
      localStorage.setItem('locale', locale)
    },
  },
}
</script>

<style scoped lang="scss">
.menu-item {
  @apply p-2;
  @apply shadow;
  @apply bg-base-100;
  @apply rounded-box;
  @apply menu;
  @apply dropdown-content;
}
</style>
