import Vue from 'vue'

import { gmxMutations, gmxActions } from '../constants.js'

export default {
  namespaced: true,
  state: () => ({
    data: [],
  }),
  mutations: {
    [gmxMutations.UPDATE_GMX_BOARD_DATA](state, data) {
      state.data = data
    },
  },
  actions: {
    async [gmxActions.LOAD_GMX_BOARD_DATA]({ commit, state }) {
      if (state.data.length > 0) {
        return
      }
      const data = await Vue.$http.get('api/v2/gmx/board')
      commit(gmxMutations.UPDATE_GMX_BOARD_DATA, data)
    },
  },
  getters: {
    logics(state) {
      return state.logics
    },
  },
}
