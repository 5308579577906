import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/manual-orders',
    name: 'manual-orders',
    component: () => import('@/views/manual-orders/index.vue'),
    meta: {
      title: 'Manual Orders',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/binance-stats',
    name: 'binance-stats',
    component: () => import('@/views/market-information/binance-stats/index.vue'),
    meta: {
      title: 'Binance Stats',
    },
  },
  {
    path: '/gmx-board',
    name: 'gmx-board',
    component: () => import('@/views/market-information/gmx-board/index.vue'),
    meta: {
      title: 'GMX Board',
    },
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('@/views/market-information/stats/index.vue'),
    meta: {
      title: 'stats',
    },
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('@/views/market-information/collections/index.vue'),
    meta: {
      title: 'Collections',
    },
  },
  {
    path: '/market-points',
    name: 'market-points',
    component: () => import('@/views/market-information/market-points/index.vue'),
    meta: {
      title: 'Market Points',
    },
  },
  {
    path: '/tweets',
    name: 'twitter',
    component: () => import('@/views/market-information/twitter/index.vue'),
    meta: {
      title: 'Twitter',
    },
  },
  {
    path: '/x-chart',
    name: 'x-chart',
    component: () => import('@/views/market-information/x-chart/index.vue'),
    meta: {
      title: 'xChart',
    },
  },
  {
    path: '/iv-band',
    name: 'iv-band',
    component: () => import('@/views/market-information/iv-band/index.vue'),
    meta: {
      title: 'IVBand',
    },
  },
  {
    path: '/logic-orders',
    name: 'LogicOrders',
    component: () => import('@/views/logic-orders/index.vue'),
    meta: {
      title: 'orders',
    },
  },
  {
    path: '/all-orders',
    name: 'All Orders',
    component: () => import('@/views/all-orders/index.vue'),
    meta: {
      title: 'All Orders',
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/index.vue'),
    meta: {
      title: 'news',
    },
  },
  {
    path: '/presets',
    name: 'presets',
    component: () => import('@/views/presets/index.vue'),
    meta: {
      title: 'presets',
    },
  },
  {
    path: '/my-bots',
    name: 'my-bots',
    component: () => import('@/views/my-bots/index.vue'),
    meta: {
      title: 'myBots',
    },
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('@/views/accounts/index.vue'),
    meta: {
      title: 'accounts',
    },
  },
  {
    path: '/',
    component: () => import('@/views/auth/index.vue'),
    meta: {
      auth: false,
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/login/index.vue'),
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/auth/logout/index.vue'),
  },
  {
    path: '/admin',
    component: () => import('@/views/admin/index.vue'),
    children: [
      {
        path: '/admin/users',
        name: 'userManagement',
        component: () => import('@/views/admin/user/index.vue'),
      },
      {
        path: '/admin/setting',
        name: 'adminSetting',
        component: () => import('@/views/admin/setting/index.vue'),
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  routes,
});

export default router;
