import Vue from 'vue'
import jwtDecode from 'jwt-decode'

export default {
  namespaced: true,
  state: () => ({
    login: false,
    token: '',
    user: null,
  }),
  mutations: {
    SET_AUTH(state, token) {
      state.login = !!token
      state.token = token
      if (token) {
        try {
          state.user = jwtDecode(token)
        } catch (err) {
          // ignore
        }
      } else {
        state.user = {}
      }
    },
  },
  actions: {
    async login ({ commit }, { name, password }) {
      const { success, token } = await Vue.$http.post('/api/v2/login', {
        name,
        password,
      })
      localStorage['local-token'] = token
      commit('SET_AUTH', token || '')
      if (!success) {
        return Promise.reject({ login: false })
      }
    },
  },
}
