import Vue from 'vue'
import { configsActions, configsMutations } from '../constants.js'

export default {
  namespaced: true,
  state: () => ({
    configs: {},
  }),
  actions: {
    async [configsActions.LOAD]({ commit }) {
      const configs = await Vue.$http.get('/api/v2/user-configs')
      commit(configsMutations.SET_CONFIGS, configs)
    },
    async [configsActions.SAVE]({ state, dispatch }, configs) {
      await Vue.$http.post('/api/v2/user-configs', {
        configs: {
          ...state.configs,
          ...configs,
        },
      })
      await dispatch(configsActions.LOAD)
    },
  },
  mutations: {
    [configsMutations.SET_CONFIGS](state, configs) {
      state.configs = configs
    },
  },
  getters: {
    manualOrders(state) {
      return state.configs?.manualOrders || []
    },
  },
}
