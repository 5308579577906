export default {
  'Edit Account Group': 'Edit Account Group',
  'New Account Group': 'New Account Group',
  'Account Group Name': 'Account Group Name',
  'Summary': 'Summary',
  'Multiple': 'Multiple',
  'RANDOM (0.9~1.1)': 'RANDOM (0.9~1.1)',
  'Exit Day Range (Lower)': 'Exit Day Range (Lower)',
  'Exit Day Range (Upper)': 'Exit Day Range (Upper)',
  'Auto Entry': 'Auto Entry',
  'Chart': 'Chart',
  'Performance': 'Performance',
  'Filter': 'Filter',
  'Select accounts': 'Select accounts',
  'Select logics': 'Select logics',
  'Inital Amount': 'Inital Amount',
  'Mark Price': 'Mark Price',
  'Open Only': 'Open Only',
  'Content': 'Content',
  'Mark': 'Mark',
  'Entry / Filled': 'Entry / Filled',
  'IVBand': 'IVBand',
  'Reset': 'Reset',
  'Setting Price': 'Setting Price',
  'Twitter Setting': 'Twitter Setting',
  'Twitter': 'Twitter',
  'Clear': 'Clear',
  'Account Name': 'Account Name',
  'Account': 'Account',
  'Accounts': 'Accounts',
  'accounts': 'Accounts',
  'Action': 'Action',
  'Actions': 'Actions',
  'Add': 'Add',
  'All Orders': 'All Orders',
  'all orders': 'All Orders',
  'Amount (USD)': 'Amount (USD)',
  'API key': 'API key',
  'Apply': 'Apply',
  'Balance': 'Balance',
  'balance': 'Balance',
  'Batch edit': 'Batch edit',
  'Batch Entry': 'Batch Entry',
  'Batch update': 'Batch update',
  'Binance Stats': 'Binance Stats',
  'Bot Name': 'Bot Name',
  'Bots': 'Bots',
  'BTC Price': 'BTC Price',
  'Buy Sell': 'Buy Sell',
  'Cancel': 'Cancel',
  'ChartError': 'Errors',
  'ChartPL': 'Chart',
  'Check span': 'Check span',
  'Check': 'Select',
  'Clone': 'Clone',
  'Close Position': 'Close Position',
  'Close': 'Close',
  'Revert': 'Revert Entry',
  'closeSuccess': 'Close order success.',
  'Collections': 'Collections',
  'Config': 'Config',
  'Copy Logic': 'Copy Logic',
  'Create': 'Create',
  'Dashboard': 'Dashboard',
  'Date': 'Date',
  'Datetime': 'Datetime',
  'Delay (seconds)': 'Delay (seconds)',
  'Delete Account': 'Delete Account',
  'Delete Group': 'Delete Group',
  'Delete': 'Delete',
  'Detail': 'Detail',
  'Edit Account': 'Edit Account',
  'Edit Group': 'Edit Group',
  'Edit': 'Edit',
  'Email': 'Email',
  'Entry price': 'Entry price',
  'Equity': 'Equity',
  'Exchange accounts': 'Exchange accounts',
  'Exchange': 'Exchange',
  'Filled': 'Filled',
  'Funding Rate': 'Funding Rate',
  'GMX Board': 'GMX Board',
  'Group Name': 'Group Name',
  'Group': 'Group',
  'groups': 'Groups',
  'Hide': 'Hide',
  'ID': 'ID',
  'Last Update': 'Last Update',
  'Limit Order': 'Limit Order',
  'Limit': 'Limit',
  'Link': 'Link',
  'Liquidation': 'Liquidation',
  'Logic Detail': 'Logic Detail',
  'Logic Name': 'Name',
  'Logic': 'Logic',
  'Logics': 'Logics',
  'Losscut': 'Losscut',
  'lxvx': 'Lxvx',
  'Make order': 'Make order',
  'Manual Orders': 'Manual Orders',
  'Market Points': 'Market Points',
  'Market': 'Market',
  'Memo': 'Memo',
  'Mint history of stable coins': 'Mint history of stable coins',
  'Move DOWN': 'Move DOWN',
  'Move UP': 'Move UP',
  'myBots': 'My Bots', // ロジック
  'Name is required': 'Name is required',
  'New Account': 'New Account',
  'New Group': 'New Group',
  'New Order': 'New Order',
  'news': 'News',
  'No Accounts': 'No Accounts',
  'No available data': 'No available data',
  'No': 'No',
  'Notification Setting': 'Notification Setting',
  'OmTrader Orders': 'OmTrader Orders',
  'Only Manual order': 'Only Manual order',
  'Order At': 'Order At',
  'Order ID': 'Order ID',
  'Order Type': 'Order Type',
  'Order': 'Order',
  'orders': 'Orders', // 注文
  'Passphrase': 'Passphrase',
  'Please enable access from IP: 52.192.42.250': 'Please enable access from IP: 52.192.42.250',
  'Please select account': 'Please select account',
  'Please select exchange': 'Please select exchange',
  'Please select group': 'Please select group',
  'Position': 'Position',
  'Positions': 'Positions',
  'presets': 'Presets',
  'Price is required': 'Price is required',
  'Price': 'Price',
  'Product ID': 'Product ID',
  'ProductID is required': 'ProductID is required',
  'ProductID': 'ProductID',
  'Profit Loss': 'Profit Loss',
  'Profit': 'Profit',
  'Qty': 'Qty',
  'Reduce Only': 'Reduce Only',
  'Remove Memo': 'Remove Memo',
  'resetFailed': 'Fail to reset logics',
  'resetLogic': 'Reset logic',
  'resetLogicConfirmText': 'Yes reset',
  'resetLogicText': 'Do you want reset this logics.',
  'resetSuccess': 'Reset logics success.',
  'Save Memo': 'Save Memo',
  'Save': 'Save',
  'Secret key': 'Secret key',
  'Show': 'Show',
  'Side': 'Side',
  'Site': 'Site',
  'Sl': 'Sl',
  'Source': 'Source',
  'Span': 'Span',
  'Stable Coin': 'Stable Coin',
  'stats': 'Market Statistics',
  'Status': 'Status',
  'Stop Order': 'Stop Order',
  'Stop Price': 'Stop Price',
  'Stop': 'Stop',
  'Sub Account': 'Sub Account',
  'Symbol': 'Symbol',
  'Token': 'Token',
  'Total': 'Total',
  'Tp': 'Tp',
  'turnOffConfirmText': 'Yes, turn off',
  'turnOffLogic': 'Turn off logic',
  'turnOffText': 'Do you want turn off this logics.',
  'turnOnConfirmText': 'Yes, turn on',
  'turnOnLogic': 'Turn on logic',
  'turnOnText': 'Do you want turn on this logics.',
  'Type': 'Type',
  'UID': 'UID',
  'Unrealised PNL': 'Unrealised PNL',
  'Update': 'Update',
  'Updated': 'Updated!!!',
  'updateFailed': 'Failed, something wrong, please try again.',
  'updateSuccess': 'Success',
  'Users Management': 'Users Management',
  'Volume (USD)': 'Volume (USD)',
  'Volume is required': 'Volume is required',
  'Volume': 'Volume',
  'xChart': 'X Chart',
  'Yes': 'Yes',
  'actions': {
    reset: 'Reset',
    on: 'On',
    off: 'Off',
    batchEdit: 'Batch edit',
  },
  'logicModal': {
    success: 'Update config success.',
    fail: 'Update config failed, please try again',
  },
}
