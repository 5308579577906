<template>
  <div class="gap-1 ml-1">
    <kbd
      class="kbd cursor-pointer kbd-sm hover:bg-gray-200"
      @click="$router.go(-1)"
    >
      <d-icon name="chevron-left" />
    </kbd>
    <kbd
      class="kbd cursor-pointer kbd-sm hover:bg-gray-200"
      @click="$router.go(1)"
    >
      <d-icon name="chevron-right" />
    </kbd>
  </div>
</template>

<script>
export default {
  name: 'app-bar-navigation',
}
</script>
