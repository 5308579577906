module.exports = {
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    $$daisyCircleable() {
      return this.circle ? 'circle' : ''
    },
  },
}
