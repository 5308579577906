<template>
  <div>
    <div
      v-for="(modal, idx) of modals"
      class="modal modal-open"
      :key="idx"
      @click="clickOutSide"
    >
      <component
        :is="modal.component"
        v-bind="modal.props"
        v-on="modal.events"
        class="modal-box"
        :modal-key="modal.key"
        :ref="modal.key"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'global-modal',

  data: () => ({
    modals: [],
  }),

  created() {
    this.install()
  },

  methods: {
    clickOutSide(event) {
      if (!event.target.classList.contains('modal')) {
        return
      }
      const modal = this.modals.slice(-1)[0]
      if (!modal) {
        return
      }

      const { autoHide, key } = modal
      if (autoHide) {
        const ref = this.$refs[key][0]
        if (!ref) {
          return
        }
        ref.$emit('onClose')
        this.removeModal(key)
      }
    },
    removeModal(key) {
      const index = this.modals.findIndex((item) => item.key === key)
      if (index < 0) {
        return
      }
      this.modals.splice(index, 1)
    },
    install() {
      Vue.prototype.$$showModal = ({
        component,
        props,
        events,
        autoHide = true,
      }) => {
        const key = Date.now()
        this.modals.push({ component, props, events, key, autoHide })
      }

      Vue.prototype.$$closeModal = (event) => {
        let counter = 0,
          el = event.srcElement,
          key,
          component
        do {
          component = el.__vue__
          key = component ? component.$attrs['modal-key'] : null
          el = el.parentNode
          counter++
        } while (!key && counter < 10)
        if (key && component) {
          this.removeModal(key)
          component.$emit('onClose')
        }
      }
    },
  },
}
</script>
