import Vue from 'vue'

import Cryptofonts from '@/components/daisy/atoms/cryptofonts/index.vue'

// Daisy atoms
import DIcon from '@/components/daisy/atoms/icons/index.vue'
import DBadge from '@/components/daisy/atoms/badge/index.vue'
import DBtn from '@/components/daisy/atoms/button/index.vue'
import DInput from '@/components/daisy/atoms/input/index.vue'
import DTabs from '@/components/daisy/molecules/tabs/index.vue'
import DSelect from '@/components/daisy/atoms/select/index.vue'
import DNumberWithSeparator from '@/components/daisy/atoms/number-with-separator/index.vue'

// Daisy molecules
import ButtonGroup from '@/components/misc/button-group/index.vue'
import DPaging from '@/components/daisy/organs/paging/index.vue'
import DMultiSelect from '@/components/daisy/molecules/multi-select/index.vue'
import DLabeledInput from '@/components/daisy/molecules/labeled-input/index.vue'
import DLabeledSelect from '@/components/daisy/molecules/labeled-select/index.vue'

// Daisy templates
import DTable from '@/components/daisy/template/table/index.vue'

// Misc
import AccountFilter from '@/components/misc/account-filter/index.vue'
import LogicFilter from '@/components/misc/logic-filter/index.vue'

Vue.component('cryptofonts', Cryptofonts)

// Daisy install
Vue.component('d-btn', DBtn)
Vue.component('d-icon', DIcon)
Vue.component('d-input', DInput)
Vue.component('d-tabs', DTabs)
Vue.component('d-select', DSelect)
Vue.component('d-badge', DBadge)
Vue.component('d-number-with-separator', DNumberWithSeparator)

Vue.component('d-paging', DPaging)
Vue.component('d-labeled-input', DLabeledInput)
Vue.component('d-labeled-select', DLabeledSelect)
Vue.component('d-multi-select', DMultiSelect)

Vue.component('d-table', DTable)

Vue.component('button-group', ButtonGroup)

Vue.component('account-filter', AccountFilter)
Vue.component('logic-filter', LogicFilter)
