<template>
  <transition name="toast">
    <div
      v-show="show"
      class="toast-notify__item"
      :class="classes"
      @mouseover="hold(true)"
      @mouseleave="hold(false)"
    >
      <div class="flex items-center">
        <div class="flex">
          <d-icon :name="icon" size="lg" />
        </div>
        <div class="ml-2">
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const map = {
  info: {
    class: 'toast-notify__info',
    icon: 'info-circle',
  },
  success: {
    class: 'toast-notify__success',
    icon: 'circle-check',
  },
  warn: {
    class: 'toast-notify__warn',
    icon: 'alert-octagon',
  },
  error: {
    class: 'toast-notify__error',
    icon: 'alert-triangle',
  },
}

export default {
  name: 'toast',

  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 2200,
    },
    type: {
      type: String,
    },
  },

  data: () => ({
    show: false,
    timer: null,
  }),

  created() {
    // make transition css hook work
    setTimeout(() => {
      this.show = true
    }, 0)
    this._setTimeout()
  },

  methods: {
    _setTimeout() {
      this.timer = setTimeout(() => {
        this.close()
      }, this.duration)
    },
    close() {
      this.show = false

      // wait for leave css effect
      setTimeout(() => {
        this.$destroy()
        this.$el.remove()
      }, 800)
    },
    hold(val) {
      if (val && this.timer) {
        clearTimeout(this.timer)
        return
      }
      this._setTimeout()
    },
  },

  computed: {
    classes() {
      return map[this.type].class
    },
    icon() {
      return map[this.type].icon
    },
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
}
</script>

<style lang="scss">
.toast-notify {
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  margin: 10px;
  overflow: hidden;
  pointer-events: none;
  flex-direction: column;
  z-index: 1000;
  align-items: flex-end;
  color: white;

  &__item {
    min-height: 65px;
    min-width: 300px;
    display: inline-flex;
    align-items: center;
    margin: 0.5em 0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 0.25em;
    pointer-events: auto;
    opacity: 0.92;
    cursor: pointer;
    padding: 10px 15px;
    width: max-content;
  }

  &__info {
    @apply bg-info;
    @apply text-white;
  }
  &__success {
    @apply bg-success;
    @apply text-white;
  }
  &__error {
    @apply bg-error;
  }
  &__warn {
    @apply bg-warning;
  }

  .toast-enter-active {
    transition: all 0.3s ease;
  }
  .toast-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .toast-enter,
  .toast-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>
