import Vuex from 'vuex'
import Vue from 'vue'

import auth from './modules/auth.js'
import common from './modules/common.js'
import collections from './modules/collections.js'
import configs from './modules/configs.js'
import credentials from './modules/credentials.js'
import gmx from './modules/gmx.js'
import logic from './modules/logic.js'
import market from './modules/market.js'
import sound from './modules/sound.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    gmx,
    sound,
    common,
    auth,
    logic,
    market,
    configs,
    collections,
    credentials,
  },
})

export default store
