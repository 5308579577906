import Vue from 'vue'

import { logicMutations } from '../constants.js'

export default {
  namespaced: true,
  state: () => ({
    logics: [],
  }),
  mutations: {
    [logicMutations.SET_LOGICS](state, logics) {
      state.logics = logics
    },
  },
  actions: {
    async loadLogics({ commit, state }, { force = false } = {}) {
      if (!force && state.logics.length > 0) {
        return
      }
      const logics = await Vue.$http.get(
        `/api/v2/Logic?filter=${JSON.stringify({
          public: false,
        })}&limit=1000`
      )
      commit(logicMutations.SET_LOGICS, logics)
    },
  },
  getters: {
    logics(state) {
      return state.logics
    },
    numberOfLogicById(state) {
      return state.logics.reduce((acc, logic) => {
        if (!acc[logic.credential]) {
          acc[logic.credential] = 0
        }
        if (logic.name !== 'Manual order') {
          acc[logic.credential]++
        }
        return acc
      }, {})
    },
    logicById(state) {
      return state.logics.reduce((acc, el) => {
        acc[el._id] = el
        return acc
      }, {})
    },
    logicByAccountId(state) {
      return state.logics.reduce((acc, el) => {
        !acc[el.credential] && (acc[el.credential] = [])
        acc[el.credential].push(el)
        return acc
      }, {})
    },
  },
}
