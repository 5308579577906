<template>
  <div>
    <img
      class="inline-flex"
      :class="classes"
      :src="`/cryptofonts/${_symbol}.svg`"
      :alt="symbol"
    />
    <span
      class="ml-1 font-semibold text-gray-600"
      v-if="!imageOnly && displayText"
    >
      {{ symbol }}
    </span>
  </div>
</template>

<script>
const SIZES = {
  sm: 'w-4',
  md: 'w-6',
  lg: 'w-12',
}

const list = ['USD_PERP', 'SWAP', 'USDT', 'USD', '_', '-', '_']

export default {
  name: 'cryptofonts',

  props: {
    size: {
      type: String,
      default: 'md',
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    symbol: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    displayText: window.screen.width > 1000,
  }),

  computed: {
    classes() {
      return SIZES[this.size]
    },
    _symbol() {
      let symbol = this.symbol
      symbol = symbol.toUpperCase()
      list.forEach((char) => {
        symbol = symbol.replace(char, '')
      })
      return symbol.toLowerCase()
    },
  },
}
</script>
