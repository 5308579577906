import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import stockInit from 'highcharts/modules/stock'
import noData from 'highcharts/modules/no-data-to-display'
import drilldownInit from 'highcharts/modules/drilldown'
import highchartsMore from 'highcharts/highcharts-more'

Vue.use(HighchartsVue)

const timezone = new Date().getTimezoneOffset()

drilldownInit(Highcharts)
stockInit(Highcharts)
highchartsMore(Highcharts)
noData(Highcharts)

Highcharts.setOptions({
  lang: {
    drillUpText: '<< Return {series.name}',
    thousandsSep: ',',
  },
  global: {
    timezoneOffset: timezone,
  },
})
