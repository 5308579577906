<template>
  <div :class="[wrapperClasses || '', isMobile ? 'mobile' : '']">
    <table class="table w-full" :class="classes || ''">
      <thead>
        <tr class="sticky top-0 z-20">
          <th class="cursor-pointer" v-if="tickAble" @click.stop="selectAll">
            <input
              type="checkbox"
              :checked="checkAll"
              class="checkbox border-solid checkbox-sm"
            />
            {{ $t('Check') }}
          </th>
          <th
            v-for="(header, idx) of headers"
            :key="idx"
            :class="header.class || 'd-table-header'"
          >
            <template v-if="header.sort">
              <span
                v-if="sortKey === header.key"
                class="mr-2 cursor-pointer select-none"
                @click.stop="sort(header)"
              >
                {{ sortSide === 'asc' ? '▲' : '▼' }}
              </span>
              <span
                v-else
                class="mr-2 cursor-pointer select-none text-gray-200"
                @click.stop="sort(header)"
              >
                {{ '▲' }}
              </span>
            </template>
            {{ header.skipI18n ? header.title : $t(header.title) }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-if="items.length === 0">
          <td :colspan="headers.length">
            <div class="flex justify-center w-full my-2">
              {{ $t('No available data') }}
            </div>
          </td>
        </tr>

        <tr
          v-else
          v-for="(item, idx) of _items"
          :key="idx"
          :class="rowClasses || 'd-table-row'"
          @click.stop="rowClick($event, item)"
        >
          <td v-if="tickAble" class="sticky left-0">
            <input
              type="checkbox"
              :checked="checkedItems.includes(item._id)"
              class="checkbox border-solid checkbox-sm"
              @click.stop="checkedItem(item)"
            />
          </td>
          <td
            v-for="key of keys"
            :key="key"
            :class="maps[key].cellClass || 'd-table-cell'"
            :data-label="maps[key].title"
            @click="
              (e) => {
                if (maps[key].cellClick) {
                  e.preventDefault()
                  maps[key].cellClick(item)
                }
              }
            "
          >
            <component
              v-if="maps[key].generator && maps[key].generator(item).component"
              :is="maps[key].generator(item).component"
              v-bind="maps[key].generator(item).props"
              v-on="maps[key].generator(item).events"
            >
              {{ maps[key].generator(item).value }}
            </component>
            <div
              v-else
              :class="maps[key].class || ''"
              v-html="maps[key].generator(item).value"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'd-table',

  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    smallText: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tickAble: {
      type: Boolean,
      default: false,
    },
    wrapperClasses: {
      type: String,
    },
    rowClasses: {
      type: String,
      default: '',
    },
    rowClick: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    sortKey: '',
    sortSide: '',
    checkedItems: [],
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.common.isMobile,
    }),
    checkAll() {
      return this.items.length && this.checkedItems.length === this.items.length
    },
    keys() {
      return this.headers.map((header) => header.key)
    },
    maps() {
      return this.headers.reduce((acc, header) => {
        acc[header.key] = header
        return acc
      }, {})
    },
    classes() {
      return [
        this.compact ? 'table-compact' : null,
        this.smallText ? 'small-text-table' : null,
      ].filter(Boolean)
    },
    _items() {
      if (!this.sortKey) {
        return this.items
      }
      const key = this.sortKey
      const sort = this.sortSide === 'asc' ? 1 : -1
      const items = [...this.items]
      items.sort((a, b) => {
        return a[key] > b[key] ? sort : -sort
      })
      return items
    },
  },

  mounted() {
    const header = this.headers.find((header) =>
      ['asc', 'desc'].includes(header.sort)
    )
    if (header) {
      this.sortSide = header.sort
      this.sortKey = header.key
    }
  },

  methods: {
    sort(header) {
      if (this.sortKey !== header.key) {
        this.sortKey = header.key
        this.sortSide = 'desc'
      } else {
        if (this.sortSide === 'asc') {
          this.sortSide = 'desc'
        } else {
          this.sortSide = 'asc'
        }
      }
    },
    selectAll() {
      if (this.checkedItems.length < this.items.length) {
        this.checkedItems = this.items.map((el) => el._id)
      } else {
        this.checkedItems = []
      }
      this.$emit('onChecked', this.checkedItems)
    },
    checkedItem(item) {
      const value = item._id
      const idx = this.checkedItems.findIndex((_value) => _value === value)
      if (idx > -1) {
        this.checkedItems.splice(idx, 1)
      } else {
        this.checkedItems.push(value)
      }
      this.$emit('onChecked', this.checkedItems)
    },
  },
}
</script>

<style lang="scss">
@media screen and (max-width: 600px) {
  .mobile {
    table {
      border: 0;

      thead {
        display: none;
      }

      tr {
        @apply bg-base-100;
        @apply mb-5;
        @apply block;
        @apply shadow drop-shadow-sm;
        @apply rounded-md;
        padding: 2px;
      }

      td,
      th {
        display: block;
        text-align: right;
        max-width: 370px !important;

        &::last-child {
          border-bottom: 0;
        }

        div {
          padding-left: 50px;
          display: flex;
          justify-content: flex-end;
        }

        a,
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 50px;
          display: block;
        }

        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
        }
      }
    }

    tbody {
      line-height: 0 !important;
    }
  }
}
</style>
