<template>
  <button
    class="btn border-solid cursor-pointer m-1 z-10"
    :class="localClasses"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <span v-if="text">
      {{ text }}
    </span>
    <slot v-else />
  </button>
</template>

<script>
import {
  classes,
  sizeable,
  colorable,
  circleable,
  disbaleable,
  outlineable,
} from '../../_mixins/index.js'

export default {
  name: 'd-button',

  mixins: [outlineable, colorable, classes, sizeable, disbaleable, circleable],

  props: {
    text: {
      type: String,
    },
    loading: {
      type: [Boolean, String],
      default: false,
    },
  },

  methods: {
    _prefixClass() {
      return 'btn'
    },
  },

  computed: {
    localClasses() {
      const classes = [this.classes]
      if (this.loading) {
        classes.push('loading')
      }
      return classes.join(' ').trimEnd()
    },
  },
}
</script>
