<template>
  <div>
    <div class="font-bold text-xl flex">App Settings</div>

    <hr class="my-2" />

    <div class="flex">
      <language-menu />
      <voice-adjutment class="ml-auto" />
    </div>

    <div class="w-full flex">
      <d-btn class="ml-auto" @click="close"> Close </d-btn>
    </div>
  </div>
</template>

<script>
import VoiceAdjutment from '../voice-adjutsment/index.vue'
import LanguageMenu from '../language-menu/index.vue'

export default {
  name: 'app-settings',

  components: {
    VoiceAdjutment,
    LanguageMenu,
  },

  methods: {
    close(event) {
      this.$$closeModal(event)
    },
  },
}
</script>
