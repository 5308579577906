import {
  SYMBOLS_BY_EXCHANGE,
} from '@/common/constants.js'

export function isHaveApiKey(exchange) {
  if (exchange.includes('binance')) {
    return true
  }
  if (exchange.includes('bybit')) {
    return true
  }
  return false
}

const BASE = {
  paper: 'coin',
  bybit_copy: 'coin',
  binance_coin_m: 'fiat',
  binance_coin_s: 'coin',
  cci: 'coin',
  bitcopy: 'fiat',
}

export function isCoinBase(exchange) {
  return BASE[exchange] === 'coin'
}


export function isHaveToken(exchange) {
  return exchange === 'bitcopy'
}

export function positionSize (position) {
  const volume = position?.size || position?.volume || 0
  if (!volume) {
    return 0
  }
  const k = position.side === 1 ? 1 : -1
  return k * volume
}

export function symbols(exchange) {
  return SYMBOLS_BY_EXCHANGE[exchange] || []
}
