import Vue from 'vue'
import { ONE_DAY } from '@/common/constants.js'

const SIDES = { 1: 'BUY', 2: 'SELL' }
const LIQUIDATION_URL = '/api/v2/liquidations'
const STABLE_COINS_URL =
  '/api/v2/public/Transaction?limit=100&sort={%22createdAt%22:-1}'

const VOLUMES = {
  bybit: 5e4,
  bitmex: 5e4,
  bitfinex: 5e3,
}

export default {
  namespaced: true,
  state: () => ({
    liquidations: [],
    stableCoins: [],
  }),
  mutations: {
    SET_STABLECOINS(state, data = []) {
      state.stableCoins = data
    },
    SET_LIQUIDATION(state, data = []) {
      state.liquidations = data
    },
  },
  actions: {
    async load({ commit, state }) {
      if (state.liquidations.length > 0) {
        return
      }
      const limit = Date.now() - 10 * ONE_DAY
      const [liquidations, stableCoins] = await Promise.all([
        Vue.$http.get(LIQUIDATION_URL),
        Vue.$http.get(STABLE_COINS_URL),
      ])
      commit(
        'SET_STABLECOINS',
        stableCoins.map((item) => ({
          ...item,
          timestamp: new Date(item.timestamp).getTime(),
        }))
      )
      commit(
        'SET_LIQUIDATION',
        liquidations
          .filter((el) => {
            if (el.timestamp > limit) {
              return el.volume > (VOLUMES[el.exchange] || 0)
            }
            return false
          })
          .sort((a, b) => {
            return b.timestamp - a.timestamp
          })
          .slice(0, 200)
          .map((item) => ({
            side: SIDES[item.side],
            price: item.price,
            volume: item.volume,
            timestamp: item.timestamp,
            exchange: item.exchange.toUpperCase(),
          }))
      )
    },
  },
}
