<template>
  <div class="flex sm:text-base text-sm">
    <d-icon name="currency-bitcoin" class="sm:block hidden" />
    <span class="font-bold w-20">
      {{ (BTCUSD || 0) | formatCurrency }}
    </span>
    <d-icon name="currency-ethereum" class="sm:block hidden ml-4" />
    <span class="font-bold sm:block hidden w-20">
      {{ (ETHUSD || 0) | formatCurrency }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'app-bar-prices',

  data: () => ({
    BTCUSD: 0,
    ETHUSD: 0,
  }),

  async mounted() {
    this.updatePrices()
    this.timer = setInterval(this.updatePrices, 3e3)
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },

  methods: {
    async updatePrices() {
      const data = await this.$http.get('/api/v2/prices', { skipLoading: true })
      this.BTCUSD = data?.BTCUSDT
      this.ETHUSD = data?.ETHUSDT
    },
  },
}
</script>
