<template>
  <div :class="classes">
    <div class="w-full text-center">
      <span v-if="label">{{ label }}</span>
      <slot v-else />
    </div>
  </div>
</template>

<script>
const typeMap = {
  success: 'text-green-800 bg-green-300',
  error: 'text-red-800 bg-red-300',
  info: 'text-blue-800 bg-blue-300',
  warn: 'text-yellow-800 bg-yellow-300',
}

export default {
  name: 'Badge',

  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'success',
    },
    label: {
      type: String,
      required: false,
    },
  },

  computed: {
    classes() {
      return [
        typeMap[this.type],
        'rounded-sm font-semibold',
        'cursor-default inline-flex text-xs',
        this.compact ? 'text-dot7rem py-0.5 px-1.5' : 'py-1 px-2',
      ]
    },
  },
}
</script>
