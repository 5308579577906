import soundMp3 from '@/assets/sounds/noti-sound.mp3'
import { soundMutations, soundActions } from '../constants.js'
import {
  ONE_MINUTE,
  SOUND_WINDOW_ID,
  SOUND_WINDOW_END,
  SOUND_VOLUME,
  SOUND_PRICE_SPAN,
} from '@/common/constants'

const BUZZ = new Audio(soundMp3)

export default {
  namespaced: true,
  state: () => ({
    ready: false,
    lock: false,
    priceNotification: {
      previous: 0,
      span: parseInt(localStorage.getItem(SOUND_PRICE_SPAN) || ONE_MINUTE),
    },
    current: {
      windowId: null,
      end: 0,
    },
    windowId: btoa(Math.random()).substr(3, 9),
    messages: [],
    volume: localStorage.getItem(SOUND_VOLUME) || 50,
  }),
  getters: {
    isNoSpeedWindow(state) {
      return !state.current.windowId || Date.now() > state.current.end
    },
    isSpeeching(state) {
      return (
        state.ready && !state.lock && state.current.windowId === state.windowId
      )
    },
  },
  mutations: {
    [soundMutations.UPDATE_PRICE_NOTIFICATION](state, price) {
      state.priceNotification.previous = Date.now()
      const date = new Date()
      const h = date.getHours()
      const m = date.getMinutes()
      state.messages.unshift(`${h}時${m}分の価格: ${price}`)
    },
    [soundMutations.UPDATE_PRICE_NOTIFICATION_SPAN](state, span) {
      span = parseInt(span)
      state.priceNotification.span = span
      localStorage.setItem(SOUND_PRICE_SPAN, span)
    },
    [soundMutations.SET_VOLUME](state, volume) {
      volume = parseInt(volume)
      state.volume = volume
      localStorage.setItem(SOUND_VOLUME, volume)
    },
    [soundMutations.MARK_READY](state) {
      state.ready = true
    },
    [soundMutations.LOCK](state) {
      state.lock = true
    },
    [soundMutations.UNLOCK](state) {
      state.lock = false
    },
    [soundMutations.SET_CURRENT_WINDOW](state, { windowId, end }) {
      state.current = { windowId, end }
    },
    [soundMutations.SHIFT_MESSAGE](state) {
      if (state.messages.length > 0) {
        state.messages = state.messages.slice(1)
      }
    },
  },
  actions: {
    [soundActions.INIT]({ commit, getters, state, rootGetters }) {
      document.addEventListener('click', _sayHello)
      function _priceCheck() {
        global.debug(`price-check@${new Date().toLocaleString()}`)
        const { previous, span } = state.priceNotification
        if (previous + span < Date.now()) {
          const price = rootGetters['market/btcusd']
          if (price) {
            global.debug('price pushed')
            commit(soundMutations.UPDATE_PRICE_NOTIFICATION, price)
          }
        }
      }

      function check() {
        commit(soundMutations.SET_CURRENT_WINDOW, _speechWindow())
        if (getters.isNoSpeedWindow || getters.isSpeeching) {
          _save(state.windowId)
        }
      }

      function _sayHello() {
        commit(soundMutations.MARK_READY)
        document.removeEventListener('click', _sayHello)
        check()
        _speech({
          volume: state.volume,
          message: 'おはようございます',
          sound: true,
        })
        setInterval(check, 10e3)
        setInterval(_priceCheck, ONE_MINUTE / 2)
        _priceCheck()
      }
    },
    async [soundActions.SPEECH]({ commit, getters, state }) {
      const message = getters.isSpeeching ? state.messages[0] : null
      if (message) {
        commit(soundMutations.SHIFT_MESSAGE)
        commit(soundMutations.LOCK)
        await _speech({ volume: state.volume, message, sound: true })
        commit(soundMutations.UNLOCK)
      }
    },
  },
}

let speechSynthesisUtterance

function _instance() {
  if (speechSynthesisUtterance) {
    return speechSynthesisUtterance
  }
  speechSynthesisUtterance = new SpeechSynthesisUtterance()
  speechSynthesisUtterance.lang = 'ja'
  speechSynthesisUtterance.rate = 1
  return speechSynthesisUtterance
}

async function _speech({ volume, message, sound = false }) {
  if (volume < 1) {
    return
  }
  _instance()

  if (sound) {
    await BUZZ.play()
    await _sleep(700)
  }

  if (/-\d+/g.test(message)) {
    message = message.replace(/(-)(\d+)/g, 'マイナス$2')
  }
  // eslint-disable-next-line
  // console.log(`speech@${new Date().toLocaleString()}: ${message}`)
  const messages = _chunks(message, 80)
  for (let message of messages) {
    await new Promise((resolve) => {
      speechSynthesisUtterance.addEventListener('end', _done)
      speechSynthesisUtterance.volume = Math.round((volume / 100) * 100) / 100
      speechSynthesisUtterance.text = message
      speechSynthesis.speak(speechSynthesisUtterance)

      function _done() {
        global.log(`done@${Date.now()}`)
        resolve(true)
        speechSynthesisUtterance.removeEventListener('end', _done)
      }
    })
  }
}

function _save(windowId) {
  localStorage.setItem(SOUND_WINDOW_ID, windowId)
  localStorage.setItem(SOUND_WINDOW_END, Date.now() + 20e3)
}

function _speechWindow() {
  const windowId = localStorage.getItem(SOUND_WINDOW_ID)
  const end = parseInt(localStorage.getItem(SOUND_WINDOW_END) || 0)
  return { windowId, end }
}

function _sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function _chunks(str, size) {
  const total = Math.ceil(str.length / size)
  const chunks = new Array(total)

  for (let i = 0, o = 0; i < total; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}
