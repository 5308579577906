const IS_DEV = process.env.NODE_ENV === 'development'

function _init() {
  /* eslint-disable no-console */
  global.log = IS_DEV ? console.log : () => {}
  global.warn = IS_DEV ? console.warn : () => {}
  global.error = IS_DEV ? console.error : () => {}
  global.debug = global.log

  if (!IS_DEV) {
    console.warn('%cStop, this is developer functions, do not allow anyone access here.', 'color: red; font-size:50px;')
  }
  /* eslint-enable no-console */
}

_init()
