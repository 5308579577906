<template>
  <div>
    <div class="text-gray-700 font-medium">Voice Adjutment</div>
    <div class="w-56 bg-base-100 rounded-box py-4">
      <div class="font-bold text-gray-500">Volume</div>
      <div class="mt-1">
        <input type="range" max="100" v-model.number="volume" class="range" />
      </div>
      <div class="font-bold text-gray-500">価格を音声通知</div>
      <div class="mt-1">
        <select
          class="select select-bordered select-sm w-full max-w-xs border-solid"
          v-model.number="priceNotificationSpan"
        >
          <option value="60000">1分</option>
          <option value="180000">3分</option>
          <option value="300000">5分</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { soundMutations } from '@/store/constants.js'

export default {
  name: 'voice-adjutment',

  computed: {
    volume: {
      get() {
        return this.$store.state.sound.volume
      },
      set(val) {
        this.$store.commit(`sound/${soundMutations.SET_VOLUME}`, parseInt(val))
      },
    },
    priceNotificationSpan: {
      get() {
        return this.$store.state.sound.priceNotification.span || 60e3
      },
      set(val) {
        this.$store.commit(
          `sound/${soundMutations.UPDATE_PRICE_NOTIFICATION_SPAN}`,
          parseInt(val)
        )
      },
    },
  },
}
</script>
