module.exports = {
  props: {
    outline: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    $$daisyOutlineable() {
      return this.outline ? 'outline' : ''
    },
  },
}
