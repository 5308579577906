<template>
  <div class="h-full bg-gray-200 flex flex-col py-2">
    <div class="mx-auto">
      <kbd class="kbd kbd-md w-12 h-10"> CRT </kbd>
    </div>

    <div class="mt-4">
      <ul class="menu compact rounded-box p-2 pb-20">
        <li class="mt-1 flex" v-for="(menu, idx) of menus" :key="idx">
          <div
            v-tooltip="menu.title"
            class="menu-item hover:bg-gray-300 px-2"
            :class="active(menu) ? 'bg-gray-100' : ''"
            @click="goto(menu.to)"
          >
            <d-icon
              :name="menu.icon"
              :size="menu.size || 'md'"
              :class="menu.class"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-drawer',

  data: () => ({
    showChartMenu: false,
    chartMenus: [
      {
        title: 'X-Chart',
        to: '/x-chart',
      },
    ],
  }),

  computed: {
    menus() {
      const isAdmin = Boolean(this.$store.state.auth.user?.isAdmin)
      return [
        {
          title: this.$t('Dashboard'),
          icon: 'home',
          to: '/dashboard',
        },
        {
          title: this.$t('orders'),
          icon: 'list',
          to: '/logic-orders',
        },
        {
          title: this.$t('news'),
          icon: 'news',
          to: '/news',
        },
        {
          title: 'Twitter',
          icon: 'brand-twitter',
          to: '/tweets',
        },
        {
          title: this.$t('presets'),
          icon: 'brand-google-analytics',
          to: '/presets',
        },
        {
          title: this.$t('myBots'),
          icon: 'brand-android',
          to: '/my-bots',
        },
        {
          title: this.$t('Accounts'),
          icon: 'wallet',
          to: '/accounts',
        },
        {
          title: this.$t('all orders'),
          icon: 'list-check',
          to: '/all-orders',
        },
        {
          title: this.$t('Manual Orders'),
          icon: 'notebook',
          to: '/manual-orders',
        },
        {
          title: 'Users Management',
          icon: 'users',
          class: 'font-bold text-red-600',
          to: '/admin/users',
          hidden: !isAdmin || !global.NO_RESTRICTED,
        },
        {
          title: 'Notification Setting',
          icon: 'setting-cog',
          class: 'font-bold text-red-600',
          to: '/admin/setting',
          hidden: !isAdmin || !global.NO_RESTRICTED,
        },
      ].filter((el) => !el.hidden)
    },
  },

  methods: {
    goto(route) {
      if (!this.$route.path.includes(route)) {
        this.$router.push(route)
        this.$emit('onClose')
      }
    },
    active(item) {
      if (this.$route.path.startsWith(item.to)) {
        return true
      }
      return false
    },
  },
}
</script>

<style scoped lang="scss">
.menu-item {
  @apply px-2;
  @apply w-12;
  @apply h-12;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply rounded-box;
  @apply cursor-pointer;
}

.todo-aaa {
  z-index: 90;
}
</style>
