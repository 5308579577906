import Vue from 'vue'
import Component from './Toast'

const SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'

function install(Vue) {
  const methods = {
    success(message, duration = 2200) {
      this._notify({ message, type: SUCCESS, duration })
    },
    info(message, duration = 2200) {
      this._notify({ message, type: INFO, duration })
    },
    warn(message, duration = 2200) {
      this._notify({ message, type: WARN, duration })
    },
    error(message, duration = 2200) {
      this._notify({ message, type: ERROR, duration })
    },
    _notify({ message, type, duration }) {
      const Toast = Vue.extend(Component)
      const el = document.createElement('div')
      document.getElementById('toast-notify').appendChild(el)
      return new Toast({
        el,
        propsData: {
          message,
          type,
          duration,
        },
      })
    },
  }
  Vue.prototype.$toast = methods
}

const Toast = { install }

Vue.use(Toast)
