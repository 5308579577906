<template>
  <input
    :disabled="disabled"
    class="input border-solid m-1 input-bordered"
    v-model="_value"
    :class="classes"
    :type="type"
    :placeholder="placeholder"
    :readOnly="readOnly"
  />
</template>

<script>
import {
  classes,
  sizeable,
  colorable,
  circleable,
  disbaleable,
  outlineable,
} from '../../_mixins/index.js'

export default {
  name: 'd-input',

  mixins: [classes, sizeable, colorable, circleable, disbaleable, outlineable],

  props: {
    disabled: Boolean,
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    _prefixClass() {
      return 'input'
    },
  },

  computed: {
    _value: {
      get() {
        return this.value
      },
      set(val) {
        if (!this.disabled) {
          this.$emit('input', val)
          this.$emit('onChange', val)
        }
      },
    },
  },
}
</script>
