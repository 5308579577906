const sizes = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs',
}

module.exports = {
  props: {
    size: {
      type: String,
      default: 'sm',
    },
  },

  methods: {
    $$daisySizeable() {
      return sizes[this.size]
    },
  },
}
