<template>
  <div class="w-full">
    <label class="label" v-if="label">
      <span class="label-text">{{ label }}</span>
    </label>
    <d-input v-model="_value" :placeholder="placeholder" :readOnly="disabled" />
  </div>
</template>

<script>
export default {
  name: 'd-labeled-input',

  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _value: {
      get() {
        return this.value
      },
      set(val) {
        ;['input', 'onChange'].forEach((event) => this.$emit(event, val))
      },
    },
  },
}
</script>
