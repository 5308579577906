<template>
  <div class="modal modal-open" ref="confirm">
    <div class="modal-box flex flex-col">
      <div class="w-full flex">
        <div class="font-bold">
          {{ title }}
        </div>

        <d-btn
          outline
          circle
          color="error"
          class="ml-auto"
          @click="close"
          size="xs"
        >
          <d-icon name="x" size="sm" />
        </d-btn>
      </div>

      <div class="mt-4 text-gray-500 text-md">
        {{ text }}
      </div>

      <div class="modal-action">
        <a class="btn-sm-primary" @click="_confirm(true)">
          {{ confirmText }}
        </a>
        <a class="btn btn-sm" @click="_confirm(false)">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alert',

  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    confirmText: {
      type: String,
      default: 'Yes',
    },
    confirmColor: {
      type: String,
      default: 'primary',
    },
    onConfirm: {
      type: Function,
      required: false,
    },
    onCancel: {
      type: Function,
      required: false,
    },
  },

  methods: {
    _confirm(value) {
      this.callback(value)
      if (value) {
        !!this.onConfirm && this.onConfirm()
      } else {
        !!this.onCancel && this.onCancel()
      }
      this.close()
    },
    close() {
      this.$refs.confirm.remove()
    },
  },
}
</script>
