<template>
  <div class="btn-group" v-if="total > 1">
    <button :class="`btn btn-${size}`" @click="pageChange(1)">First</button>
    <button
      :class="`btn btn-${size}`"
      @click="pageChange(Math.max(value - 1, 1))"
    >
      «
    </button>

    <button
      v-for="(_page, idx) of pages"
      :key="idx"
      :class="classes(_page)"
      @click="pageChange(_page)"
      :disabled="_page === value"
    >
      {{ _page }}
    </button>

    <button
      :class="`btn btn-${size}`"
      @click="pageChange(Math.min(value + 1, total))"
    >
      »
    </button>
    <button :class="`btn btn-${size}`" @click="pageChange(total)">Last</button>
  </div>
</template>

<script>
const VISIABLE = 3

export default {
  name: 'd-paging',

  props: {
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },

  computed: {
    pages() {
      const start = Math.max(this.value - VISIABLE, 1)
      const end = Math.min(start + 4, this.total)
      const res = []
      for (let i = start; i <= end; i++) {
        res.push(i)
      }
      return res
    },
  },

  methods: {
    pageChange(page) {
      this.$emit('pageChange', page)
      this.$emit('input', page)
    },
    classes(i) {
      const classes = ['btn', `btn-${this.size}`]
      if (i === this.value) {
        classes.push('btn-active')
      }
      return classes.join(' ')
    },
  },
}
</script>
