<template>
  <input type="text" v-model="_value" />
</template>

<script>
export default {
  name: 'd-number-with-separator',

  props: {
    value: Number,
  },

  computed: {
    _value: {
      get() {
        return new Intl.NumberFormat('en-US').format(parseFloat(this.value))
      },
      set(newValue) {
        const value = parseFloat(newValue.replace(/,/g, ''))
        this.$emit('input', isNaN(value) ? 0 : value)
      },
    },
  },
}
</script>
