const configsActions = {
  LOAD: 'load',
  SAVE: 'save',
}

const credentialsActions = {
  LOAD_CREDENTIALS: 'loadCredentials',
  LOAD_GROUPS: 'loadGroups',
  RE_ORDER_GROUPS: 'reOrderGroups',
  RE_ORDER_ACCOUNTS: 'reOrderAcounts',
}

const marketActions = {
  SYNC: 'sync',
  SYNC_STATS: 'syncStats',
}

const soundActions = {
  INIT: 'init',
  SPEECH: 'speech',
}

const gmxActions = {
  LOAD_GMX_BOARD_DATA: 'loadGmxBoardData',
}

const configsMutations = {
  SET_CONFIGS: 'SET_CONFIGS',
}

const credentialsMutations = {
  SET_CREDENTIALS: 'SET_CREDENTIALS',
  SET_GROUPS: 'SET_GROUPS',
  ADD_CREDENTIAL: 'ADD_CREDENTIAL',
  ADD_GROUP: 'ADD_GROUP',
  UPDATE_CREDENTIAL: 'UPDATE_CREDENTIAL',
}

const marketMutations = {
  UPDATE_OI: 'UPDATE_OI',
  UPDATE_PRICES: 'UPDATE_PRICES',
  UPDATE_WEEKGAP: 'UPDATE_WEEKGAP',
  UPDATE_MISC: 'UPDATE_MISC',
  UPDATE_STATS: 'UPDATE_STATS',
  UPDATE_LONGSHORT: 'UPDATE_LONGSHORT',
  UPDATE_MISC_FROM_TOOL: 'UPDATE_MISC_FROM_TOOL',
}

const logicMutations = {
  SET_LOGICS: 'SET_LOGICS',
}

const soundMutations = {
  MARK_READY: 'MARK_READY',
  LOCK: 'LOCK',
  UNLOCK: 'UNLOCK',
  UPDATE_PRICE_NOTIFICATION: 'UPDATE_PRICE_NOTIFICATION',
  SET_CURRENT_WINDOW: 'SET_CURRENT_WINDOW',
  SHIFT_MESSAGE: 'SHIFT_MESSAGE',
  SET_VOLUME: 'SET_VOLUME',
  UPDATE_PRICE_NOTIFICATION_SPAN: 'UPDATE_PRICE_NOTIFICATION_SPAN',
}

const gmxMutations = {
  UPDATE_GMX_BOARD_DATA: 'UPDATE_GMX_BOARD_DATA',
}

module.exports = {
  configsActions,
  configsMutations,
  credentialsActions,
  credentialsMutations,
  gmxActions,
  gmxMutations,
  logicMutations,
  marketActions,
  marketMutations,
  soundActions,
  soundMutations,
}
