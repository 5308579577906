<template>
  <div class="navbar shadow min-h-12 sticky top-0 z-50 bg-base-100">
    <label
      for="nav-drawer"
      class="drawer-overlay hover:bg-gray-200 lg:hidden rounded-btn"
    >
      <d-icon name="menu-2" color="black" />
    </label>
    <Navigation />
    <div class="ml-1 sm:text-lg text-sm font-bold">
      {{ $t($route.meta.title) }}
    </div>
    <div class="ml-auto">
      <prices />
      <user-menu class="ml-1" />
    </div>
  </div>
</template>

<script>
import UserMenu from '../user-menu/index.vue'
import Navigation from '@/components/apps/app-bar/navigation/index.vue'
import Prices from '@/components/apps/app-bar/prices/index.vue'

export default {
  name: 'app-bar',

  components: {
    UserMenu,
    Prices,
    Navigation,
  },
}
</script>
