<template>
  <select
    :disabled="disabled"
    class="select select-bordered border-solid select-sm w-full m-1"
    v-model="_value"
  >
    <option disabled="disabled" selected="selected">
      {{ placeholder }}
    </option>
    <option
      v-for="(item, idx) in items"
      :key="idx"
      :value="'value' in item ? item.value : item"
    >
      {{ text(item) }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'd-select',

  props: {
    disabled: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
    },
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    _value: {
      get() {
        return this.value
      },
      set(value) {
        const option = this.items.find((el) => el.value === value)
        this.$emit('input', value, option)
      },
    },
  },

  methods: {
    text(item) {
      return item.text || item.label || item
    },
  },
}
</script>
