<template>
  <div class="dropdown dropdown-end cursor-pointer">
    <kbd tabindex="0" class="kbd kbd-md">
      <d-icon name="dots" />
    </kbd>
    <ul
      tabindex="0"
      class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52"
    >
      <div class="dropdown-user" v-if="user">
        <d-icon name="user" />
        <div class="ml-2">
          <div class="font-bold">{{ user.name }}</div>
          <div class="text-gray-400 text-2xs font-bold">
            <span class="user-email">{{ user.email }}</span>
          </div>
        </div>
      </div>

      <hr class="mb-2" />

      <div
        v-for="(item, idx) of items"
        class="dropdown-item"
        :key="idx"
        :class="item.class"
        @click.stop="item.handler"
      >
        <d-icon :name="item.icon" />
        <span class="ml-2"> {{ item.title }} </span>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppSettings from '../app-settings/index.vue'
import UpdateProfileModal from '../update-profile-modal/index.vue'

export default {
  name: 'user-menu',

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    items() {
      return [
        {
          title: 'Profile',
          icon: 'pencil',
          class: 'text-gray-600',
          handler: () => {
            this.$$showModal({
              component: UpdateProfileModal,
              autoHide: true,
            })
          },
        },
        {
          title: 'Settings',
          icon: 'settings',
          class: 'text-gray-600',
          handler: () => {
            this.$$showModal({
              component: AppSettings,
              autoHide: true,
            })
          },
        },
        {
          title: 'Logout',
          icon: 'logout',
          class: 'text-red-400',
          handler: () => {
            this.$router.push({ name: 'logout' })
          },
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.dropdown-user {
  @apply p-2 flex items-center rounded-md;
}

.dropdown-item {
  @apply p-2 flex items-center rounded-md hover:bg-base-300 font-medium;
}
</style>
