import store from '@/store/index.js'
import { marketMutations } from '@/store/constants.js'

setTimeout(_init, 100)

function _init() {
  const socketUrl = process.env.VUE_APP_WS_URL || `wss://sapi.${global.DOMAIN}/socket`
  const webSocket = new WebSocket(socketUrl)

  webSocket.addEventListener('open', () => {
    webSocket.onmessage = (event) => {
      if (event.data === 'ping') {
        webSocket.send('pong')
        return
      }

      const { channel, data } = JSON.parse(event.data)

      if (channel === 'price') {
        const price = parseFloat(data.price)
        price && store.commit('market/UPDATE_PRICES', {
          symbol: data.symbol,
          price: parseFloat(data.price),
        })
      } else if (channel === 'weekGap') {
        const UPDATE_WEEKGAP = `market/${marketMutations.UPDATE_WEEKGAP}`
        store.commit(UPDATE_WEEKGAP, data)
      } else {
        // eslint-disable-next-line no-console
        console.debug('message', {
          channel,
          data,
        })
      }
    }

    webSocket.onerror = () => webSocket.close()

    webSocket.onclose = () => {
      setTimeout(_init, 10e3)
    }
  })
}
