<template>
  <div class="w-full">
    <label class="label" v-if="label">
      <div class="label-text">
        {{ label }}
      </div>
    </label>
    <d-select :items="items" :placeholder="placeholder" v-model="_value" />
  </div>
</template>

<script>
export default {
  name: 'd-labeled-select',

  props: {
    label: {
      type: String,
      require: true,
    },
    placeholder: {
      type: String,
      default: 'Select item',
    },
    value: {
      type: [Number, String],
    },
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    _value: {
      get() {
        return this.value
      },
      set(val) {
        ;['input', 'onChange'].forEach((event) => this.$emit(event, val))
      },
    },
  },
}
</script>
