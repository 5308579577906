function _buildClass(prefix, suffix) {
  if (suffix) {
    return `${prefix}-${suffix}`
  }
  return ''
}

module.exports = {
  computed: {
    classes() {
      const prefix = this._prefixClass()
      const funcs = Object.keys(this).filter((item) => item.startsWith('$$daisy'))
      return funcs.map((func) => _buildClass(prefix, this[func]()))
        .join(' ')
        .trimEnd()
    },
  },
}