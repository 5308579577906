import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store/index.js'
import router from '@/router/index.js'
import i18n from '@/plugins/i18n/index.js'
import VueApexCharts from 'vue-apexcharts'
import '@/plugins/confirm/index.js'
import '@/plugins/global-components/index.js'
import '@/plugins/highchart/index.js'
import '@/plugins/http/index.js'
import '@/plugins/logger/index.js'
import '@/plugins/mts/index.js'
import '@/plugins/socket/index.js'
import '@/plugins/toast/index.js'
import '@/plugins/tooltip/index.js'
import '@/plugins/vue-meta/index.js'

import '@/scss/index.scss'
Vue.config.productionTip = false
global.IS_MOBILE = window.screen.width < 600
// localStorage.XCJ_IS_DEBUG = 'ccXDK7YsWKB5g73mKvKvGwS'
global.IS_DEBUG = localStorage.getItem('XCJ_IS_DEBUG') === 'ccXDK7YsWKB5g73mKvKvGwS'
global.DOMAIN = 'cryp-trades.com'
if (window.location.hostname !== 'localhost') {
  global.DOMAIN = window.location.hostname.split('.').slice(-2).join('.')
}

global.CACHES = {}
global.IS_LOCAL = window.location.href.includes('localhost')
global.NO_RESTRICTED = global.DOMAIN === 'cryp-trades.com'
if (global.IS_LOCAL) {
  global.NO_RESTRICTED = true
}

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
