import Vue from 'vue'
import {
  twoDigit,
  fix2Number,
  fix4Number,
  formatNumber,
  formatDate,
} from '@/utils/string/index.js'

const eps = -1e3

function install(Vue) {
  Vue.mixin({
    _methods: {
      $$colorLevel(mid, diff, value, revert = false) {
        let cls = ''
        if (revert) {
          cls = value >= mid ? 'red' : 'green'
        } else {
          cls = value >= mid ? 'green' : 'red'
        }
        const span = diff / 5
        let level = Math.ceil(Math.abs(value - mid) / span)
        level = Math.min(level, 4)
        return `${cls}-${level}`
      },
    },
    get methods() {
      return this._methods
    },
    set methods(value) {
      this._methods = value
    },
    filters: {
      formatCurrency(value) {
        return `$${formatNumber(value)}`
      },
      round(number) {
        return Math.round(number)
      },
      side(val) {
        return val === 1 ? 'BUY' : 'SELL'
      },
      formatNumber(number, __default) {
        return formatNumber(number, __default)
      },
      formatDate(val) {
        return formatDate(val)
      },
      formatShortDate(val) {
        if (!val) {
          return '-'
        }
        const date = new Date(val)
        const month = twoDigit(date.getMonth() + 1)
        const day = twoDigit(date.getDate())
        const hour = twoDigit(date.getHours())
        const min = twoDigit(date.getMinutes())
        const sec = twoDigit(date.getSeconds())
        return `${month}/${day} ${hour}:${min}:${sec}`
      },
      formatOrderId(val) {
        if (!val) {
          return '-'
        }
        return `${val.substring(0, 7)}...`
      },
      percentage2(val) {
        if (typeof val !== 'number') {
          return val
        }
        val = val * 100
        if (Math.abs(val) < eps) {
          return '0%'
        }
        return fix2Number(val) + '%'
      },
      percentage4(val) {
        if (typeof val !== 'number') {
          return val
        }
        val = val * 100
        if (Math.abs(val) < eps) {
          return '0%'
        }
        return fix4Number(val) + '%'
      },
      percentage(val) {
        if (typeof val !== 'number') {
          return val
        }
        if (Math.abs(val) < eps) {
          return '--'
        }
        return Math.round(val * 10000) / 100 + '%'
      },
      fixedTwo(number) {
        return Math.round(number * 100) / 100
      },
      bigNumber(val) {
        if (typeof val !== 'number') {
          return val
        }

        if (Math.abs(val) < eps) {
          return '-'
        }

        if (val > 1e9) {
          return Number(fix2Number(val / 1e9)) + 'B'
        }
        if (val > 1e6) {
          return Number(fix2Number(val / 1e6)) + 'M'
        }
        if (val > 1e3) {
          return Number(fix2Number(val / 1e3)) + 'K'
        }

        return val
      },
    },
  })
}

const Mts = { install }
Vue.use(Mts)
