const colors = {
  primary: 'primary',
  secondary: 'secondary',
  warning: 'warning',
  error: 'error',
  info: 'info',
}

module.exports = {
  props: {
    color: {
      type: String,
    },
  },

  methods: {
    $$daisyColorable() {
      return colors[this.color] || ''
    },
  },
}
