import Vue from 'vue'
import { isCoinBase } from '@/utils/exchange'

export default {
  namespaced: true,
  state: () => ({
    credentials: [],
    groups: [],
    balances: {},
  }),
  mutations: {
    SET_CREDENTIALS(state, credentials) {
      state.credentials = credentials
      state.groups = [...state.groups]
    },
    SET_GROUPS(state, groups) {
      state.groups = groups
    },
    ADD_GROUP(state, group) {
      state.groups.push(group)
    },
    ADD_CREDENTIAL(state, credential) {
      state.credentials.push(credential)
    },
    UPDATE_CREDENTIAL(state, credential) {
      const index = state.credentials.findIndex(
        ({ _id }) => _id === credential._id
      )
      if (index > -1) {
        state.credentials.splice(index, 1, credential)
      }
    },
    SET_BALANCES(state, balances) {
      state.balances = balances
    },
  },
  actions: {
    async reOrderGroups(
      { state, dispatch },
      { group, change }
    ) {
      const [originOrder, newOrder] = [group.order, group.order + change]
      const map = {
        [newOrder]: originOrder,
        [originOrder]: newOrder,
      }
      const orders = state.groups.reduce((acc, group) => {
        acc[group._id] = map[group.order] || group.order
        return acc
      }, {})
      await Vue.$http.post('/api/v2/groups/order', { orders })
      dispatch('loadGroups', { force: true })
    },
    async reOrderAcounts(
      { state, dispatch },
      { account, change }
    ) {
      const [originOrder, newOrder] = [account.order, account.order + change]
      const map = {
        [newOrder]: originOrder,
        [originOrder]: newOrder,
      }
      const orders = state.credentials.reduce((acc, credential) => {
        acc[credential._id] = map[credential.order] || credential.order
        return acc
      }, {})
      await Vue.$http.post('/api/v2/credentials/order', { orders })
      dispatch('loadCredentials', { force: true })
    },
    async loadGroups(
      { commit, state },
      { force = false } = {}
    ) {
      if (!force && state.groups.length > 0) {
        return
      }
      const groups = await Vue.$http.get('/api/v2/Group?select=_id name order')
      groups.forEach((group, index) => {
        group.order = group.order || index + 100
      })
      groups.sort((a, b) => a.order - b.order)
      groups.forEach((group, index) => {
        group.order = index + 1
      })
      commit('SET_GROUPS', groups)
    },
    async loadBalances({ commit, state }) {
      if (Object.keys(state.balances).length) {
        return
      }
      const balances = await Vue.$http.get('/api/v2/balances', {
        skipLoading: true,
      })
      commit('SET_BALANCES', balances)
    },
    async loadCredentials(
      { commit, state },
      { force = false } = {}
    ) {
      if (!force && state.credentials.length > 0) {
        return
      }
      const credentials = await Vue.$http.get('/api/v2/account', {
        skipLoading: true,
      })
      commit('SET_CREDENTIALS', credentials.map(el => {
        if (!el.autoEntryConfig) {
          el.autoEntryConfig = {}
        }
        el.isCoinBase = isCoinBase(el.exchange)
        el.isGroup = el.exchange === 'group'
        el.isPaper = el.exchange === 'paper'
        return el
      }))
      commit(
        'SET_GROUPS',
        state.groups.map((group) => ({
          ...group,
          key: `${group._id}_${group.total}_${Date.now()}`,
        }))
      )
      return Math.max(...credentials.map((el) => new Date(el.updatedAt).getTime()))
    },
  },
  getters: {
    balances(state) {
      return state.balances
    },
    groupOptions(state) {
      return state.groups.map((group) => ({
        value: group._id,
        label: group.name,
      }))
    },
    accountOptions(state) {
      return state.credentials.map((credential) => ({
        value: credential._id,
        label: credential.name,
      }))
    },
    validAccountOptions(state) {
      return state.credentials
        .filter(el => !el.isGroup)
        .filter((el) => el.status === 1)
        .map((credential) => ({
          value: credential._id,
          label: credential.name,
        }))
    },
    credentials(state) {
      return state.credentials
    },
    accountName(state) {
      return state.credentials.reduce((acc, el) => {
        acc[el._id] = el.name
        return acc
      }, {})
    },
    accountMap(state) {
      return state.credentials.reduce((acc, el) => {
        acc[el._id] = el
        return acc
      }, {})
    },
    groups(state) {
      return state.groups.map((group) => {
        group.accounts = state.credentials
          .filter(({ group: id }) => id === group._id)
          .map((el, idx) => {
            el.order = el.order || idx + 100
            return el
          })
          .sort((a, b) => a.order - b.order)
        group.accounts.forEach((el, index) => {
          el.order = index + 1
        })
        group.total = group.accounts.length
        group.key = `${group._id}_${group.total}_${Date.now()}`
        return group
      })
    },
    group(state, getters) {
      return (id) => {
        return getters.groups.find((gr) => gr._id === id)
      }
    },
  },
}
