<template>
  <div class="dropdown dropdown-end" :blur="$emit('onChange', checkedItems)">
    <d-input tabindex="0" class="m-1" v-model="displayText" readOnly />

    <div tabindex="0" class="f-select-content">
      <div class="w-full flex gap-2 items-center">
        <d-input placeholder="Filter" class="w-5/6" v-model="filter" />
        <d-icon
          name="refresh"
          size="sm"
          class="cursor-pointer ml-auto"
          @click="resetCheckedItems"
        />
      </div>
      <div class="h-64 overflow-y-scroll mt-2">
        <div
          class="text-sm p-2 hover:bg-base-300 rounded-md"
          v-for="(item, idx) of convertedItems"
          :key="idx"
        >
          <div class="w-full flex">
            <input
              type="checkbox"
              class="checkbox border-solid checkbox-sm"
              :id="item.value + 'multi-select'"
              :value="item.value"
              v-model="checkedItems"
            />
            <label :for="item.value + 'multi-select'" class="ml-2 w-44">
              <span class="item-text">{{ item.text }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'd-multi-select',

  props: {
    items: {
      type: Array,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    values: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    filter: '',
    checkedItems: [],
  }),

  created() {
    this._items = this.items.map((item) => {
      item._check = false
      return item
    })
  },

  mounted() {
    this.checkedItems = this.values
  },

  computed: {
    displayText() {
      if (this.checkedItems.length) {
        return `Selected ${this.checkedItems.length} items`
      }
      return this.placeholder
    },
    selected() {
      return this.convertedItems.filter((item) => item._check)
    },
    convertedItems() {
      return this.items
        .filter((item) => item.text.includes(this.filter))
        .map((item) => {
          item._check = false
          return item
        })
    },
  },

  methods: {
    resetCheckedItems() {
      this.checkedItems = []
    },
  },
}
</script>
