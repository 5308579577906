import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ja from './messages/ja.js'
import en from './messages/en.js'

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('locale') || 'ja',
  messages: {
    en,
    ja,
  },
})
