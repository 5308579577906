<template>
  <svg
    @click="$emit('click', $event)"
    xmlns="http://www.w3.org/2000/svg"
    :width="_size"
    :height="_size"
    viewBox="0 0 24 24"
    :stroke-width="strokeWidth"
    :stroke="color"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    v-html="icons[name]"
  />
</template>

<script>
const icons = require('./tablericons.json')

export default {
  name: 'XIcon',

  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    strokeWidth: {
      type: [Number, String],
      default: 1.5,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },

  computed: {
    _size() {
      /* eslint-disable */
      switch (this.size) {
        case 'md':
          return 24
        case 'sm':
          return 20
        case 'xs':
          return 10
        case 'lg':
          return 32
        default:
          return 24
      }
      /* eslint-enable */
    },
  },

  data() {
    return {
      icons,
    }
  },
}
</script>
