<template>
  <div class="drawer drawer-mobile h-screen">
    <div class="spinner" v-if="loading">
      <pulse-loader class="text-primary" />
    </div>
    <template v-if="ready">
      <input
        id="nav-drawer"
        type="checkbox"
        class="drawer-toggle"
        v-model="open"
      />
      <div class="drawer-content">
        <app-bar v-if="login" />
        <div class="m-1">
          <router-view />
        </div>
      </div>
      <div v-if="login" class="drawer-side h-screen">
        <label for="nav-drawer" class="drawer-overlay" />
        <nav-drawer @onClose="() => (open = false)" class="w-16" />
      </div>
    </template>
    <div id="toast-notify" />
    <div id="confirm-modal" />
    <global-modal />
    <div class="absolute bottom-0 right-0 p-2 opacity-70 md:hidden block">
      <x-icon
        class="btn-info btn-circle btn-lg"
        name="device-mobile"
        @click.stop="toggle"
      />
    </div>
  </div>
</template>

<script>
import XIcon from '@/components/daisy/atoms/icons/index.vue'
import { mapGetters, mapState } from 'vuex'
import constants from '@/common/constants.js'
import {
  configsActions,
  credentialsActions,
  marketActions,
  soundActions,
} from '@/store/constants.js'
import AppBar from '@/components/daisy/organs/app-bar/index.vue'
import NavDrawer from '@/components/daisy/organs/nav-drawer/index.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '@/components/apps/global-modal/index.vue'

export default {
  name: 'App',

  components: {
    AppBar,
    NavDrawer,
    PulseLoader,
    GlobalModal,
    XIcon,
  },

  meta: {
    titleTemplate: '%s - Cryptrades',
  },

  data: () => ({
    open: false,
    ready: false,
  }),

  async mounted() {
    this.check()
  },

  computed: {
    ...mapGetters({
      loading: 'common/isLoading',
    }),
    ...mapState({
      login: (state) => state.auth.login,
    }),
  },

  watch: {
    $route(to, from) {
      if (from.name === 'login') {
        this.check(true)
      }
    },
  },

  methods: {
    async check(force = false) {
      const routeName = this.$route.name
      if (!routeName) {
        setTimeout(this.check, 100)
        return
      }
      if (routeName === 'logout') {
        this.ready = true
        return
      }

      const token = localStorage['local-token']
      token ? this.init(token) : this.toLogin()
      const me = await this.$http.get('/api/v2/me')
      if (!me._id) {
        this.ready = true
        this.$router.push({ name: 'logout' })
        return
      }
      await this.loadAccounts(force)
      this.$store.dispatch(`market/${marketActions.SYNC}`)
      this.ready = true
    },
    init(token) {
      this.$store.commit('auth/SET_AUTH', token || '')
      this.$store.dispatch(`sound/${soundActions.INIT}`)
      setInterval(this.$store.dispatch, 3e3, `sound/${soundActions.SPEECH}`)
      this.$store.dispatch(`configs/${configsActions.LOAD}`)
      if (this.$route.name === 'login') {
        this.$router.push({ name: 'dashboard' })
      }
      window.onstorage = this.syncLogout
    },
    toLogin() {
      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login' })
      }
    },
    syncLogout(event) {
      if (event.key === constants.LOGOUT_KEY) {
        this.$store.commit('auth/SET_AUTH', '')
        this.$router.push({ name: 'login' })
      }
    },
    async loadAccounts(force = false) {
      this.$store.commit('common/INCREASE_COUNTER')
      await this.$store.dispatch(
        `credentials/${credentialsActions.LOAD_CREDENTIALS}`,
        { force }
      )
      await this.$store.dispatch(
        `credentials/${credentialsActions.LOAD_GROUPS}`,
        { force }
      )
      this.$store.dispatch('logic/loadLogics')
      this.$store.commit('common/DECREASE_COUNTER')
      this.ready = true
    },
    toggle() {
      this.$store.commit('common/TOGGLE_MOBILE')
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply w-screen;
  @apply h-screen;
  @apply absolute;
  @apply bg-white;
  @apply opacity-80;
  z-index: 1000;
}

#toast-notify {
  @apply fixed;
  @apply top-14;
  @apply right-4;
  @apply flex;
  @apply flex-col;
  z-index: 1000;
}
</style>
